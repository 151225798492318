<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      <el-breadcrumb-item>学校统计</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">学校统计</div>
        <div>
          <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box_a">
          <span>学校：</span>
          <el-select
            size="small"
            class="int_width"
            v-model="school_no"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="value in schoolList"
              :key="value.school_no"
              :label="value.school_name"
              :value="value.school_no"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="school_name" label="学校名称"></el-table-column>
          <el-table-column prop="school_no" label="学校编号"></el-table-column>
          <el-table-column prop="class_num" label="班级数量"></el-table-column>
          <el-table-column prop="student_num" label="学生数量"></el-table-column>
          <el-table-column prop="teacher_num" label="老师数量"></el-table-column>
           <el-table-column prop="exam_num" label="考试次数"></el-table-column>
          <el-table-column prop="attend_class_num" label="上课次数"></el-table-column>

        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      school_no: '',
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      schoolList: []
    }
  },
  mounted() {
    this.name = this.$route.query.famous_name || ''
    this.index()
    this.school()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/school/schooldata', {
        params: {
          page: this.page,
          limit: this.limit,
          school_no: this.school_no,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
    // 列表
    async school() {
      const { data: res } = await this.$http.get('edulogin.php/school/index', {
        params: {
          page: 1,
          limit: 999,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.schoolList = res.data.list
    },
    // 查询
    chaxun() {
      this.page = 1
      this.index()
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
